import { Container, HStack, Text, Flex, Box, TableContainer, Table, Tr, Image, Tbody, Thead, Th, Td } from '@chakra-ui/react'
import React from 'react'
import OutlinedButton from '../common/OutlinedButton'
import { MdOutlineClose } from "react-icons/md";

export default function Wishlist() {


    const wishlist_table_data = [
        {
            imgUrl: './home_row2_1.png',
            Price: 12000,
            name: 'lorum dollar tshirt with cotton fabric',
            quantity: 2,
            size: 'XS'
        },
        {
            imgUrl: './home_row2_2.png',
            Price: 12000,
            name: 'lorum dollar tshirt',
            quantity: 2,
            size: 'XS'
        },
        {
            imgUrl: './home_row2_3.png',
            Price: 12000,
            name: 'lorum dollar tshirt',
            quantity: 2,
            size: 'XS'
        }
    ]

    return (
        <Container
            maxW={'8xl'}
            minH={'lg'}
            maxH={'2xl'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Text
                as={'h1'}
                fontSize={'35px'}
                w={'100%'}
                textAlign={'center'}
                my={8}
                pl={10}
            >
                Wishlist
            </Text>
            <Flex
                mb={7}
                w='100%'
                justifyContent={'center'}
                alignItems={'center'}>
                <TableContainer
                    w={'100%'}
                    pl={20}
                    h={'60vh'}
                    overflowY={'auto'}
                >
                    <Table
                        h={'100%'}
                        w={'100%'}
                    >
                        <Thead
                            h={'4vh'}
                            w={'100%'}
                            borderBottom={'2px solid grey'}
                            py='1'
                        >
                            <Tr>
                                <Th
                                    className='customfont'
                                    fontSize={'sm'}
                                    color={'#000000'}
                                    w='50%'
                                >Product</Th>
                                <Th
                                    className='customfont'
                                    fontSize={'sm'}
                                    color={'#000000'}
                                >Price</Th>
                                <Th
                                    className='customfont'
                                    fontSize={'sm'}
                                    color={'#000000'}
                                    w={'100%'}
                                    textAlign={'start'}
                                >Stock Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody w={'100%'}
                            borderY={'1px solid #D9D9D9'}
                        >
                            {
                                wishlist_table_data.map((item, index) => {
                                    return <Tr
                                        key={index}
                                        h={'8vh'}
                                        w={'100%'}>
                                        <Td
                                            className='customfont'
                                            w={'50%'}
                                        >
                                            <HStack gap={5}>
                                                <Image src={item.imgUrl} alt=''
                                                    w={'40'} />
                                                <Text
                                                    fontSize={'lg'}
                                                    color={'#000000'}
                                                    fontWeight={500}
                                                >{item.name}</Text>
                                            </HStack>

                                        </Td>
                                        <Td
                                            fontSize={'xl'}
                                            color={'#000000'}
                                            fontWeight={500}
                                            className='customfont'
                                        >
                                            ₹{item.Price}
                                        </Td>
                                        <Td>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                borderRadius={'md'}
                                                w={'90%'}
                                                className='customfont'
                                            >
                                                <Text
                                                    fontSize={'xl'}
                                                    color={'#000000'}
                                                    h={'100%'}
                                                    textAlign={'center'}
                                                >IN STOCK</Text>
                                                <OutlinedButton
                                                    w='180px'
                                                    border='none'
                                                    rounded='0'
                                                    bg='#1c1c1c'
                                                    color='#fefefe'
                                                    h='60px'
                                                    fontSize='md'
                                                >
                                                    SELECT OPTIONS
                                                </OutlinedButton>
                                                <MdOutlineClose
                                                    color='grey'
                                                    size={30} />
                                            </Box>
                                        </Td>
                                    </Tr>
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        </Container>
    )
}

