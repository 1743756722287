import React from 'react'
import { Flex, Text, Image, VStack, SimpleGrid } from '@chakra-ui/react'

export default function PlaceSale() {
    return (
        <VStack
            minH={'40vh'}
            w={'100vw'}
            my={4}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Flex
                h={'27vh'}
                w={'90%'}
                borderRadius={'xl'}
                justifyContent={'center'}
                alignItems={'center'}
                bg={'#F4F1EF'}
            >
                <Text
                    textAlign={'center'}
                    color={'#000000'}
                    fontSize={'xl'}
                >
                    Place Sale Ad here
                </Text>
            </Flex>

            <SimpleGrid columns={[1, 1, 3, 3]} w='100%' justifyContent='space-around' my='4' spacing='4'>
                <VStack
                    gap={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    w='100%'
                >
                    <Image
                        src={'/shipping_icon.png'}
                        alt=''
                        width={['24px', '24px', '24px', '64px']}
                    />
                    <Text
                        fontSize={['md', 'md', 'xl', '2xl']}
                        textAlign={'center'}
                        color={'gray.600'}
                        fontWeight='800'
                    >
                        Free Shipping
                    </Text>
                    <Text
                        color={'#000000'}
                        fontSize={['sm', 'sm', 'md', 'lg']}
                        textAlign={'center'}
                    >
                        Free Shipping for 999 and up
                    </Text>
                </VStack>


                <VStack
                    w='100%'
                    gap={2}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Image src={'/Transaction.png'} alt='' width={['24px', '24px', '24px', '64px']} />
                    <Text
                        textAlign={'center'}
                        color={'gray.600'}
                        fontWeight='800'
                        fontSize={['md', 'md', 'xl', '2xl']}
                    >
                        Refund Policy
                    </Text>
                    <Text
                        color={'#000000'}
                        fontSize={['sm', 'sm', 'md', 'lg']}
                        alignItems={'center'}
                    >
                        Refund when defective product
                    </Text>
                </VStack>


                <VStack
                    w={'100%'}
                    gap={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Image src={'/Certificate.png'} alt='' width={['24px', '24px', '24px', '64px']} />
                    <Text
                        fontSize={['md', 'md', 'xl', '2xl']}
                        textAlign={'center'}
                        color={'gray.600'}
                        fontWeight='800'
                    >
                        Safe of users
                    </Text>
                    <Text color={'#000000'}
                        fontSize={['sm', 'sm', 'md', 'lg']}
                        alignItems={'center'}>
                        Cotton fabric is safe for user's health
                    </Text>
                </VStack>
            </SimpleGrid>
        </VStack>
    )
}
