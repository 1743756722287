import React from 'react'
import { SimpleGrid, Text, Container, VStack, AspectRatio } from "@chakra-ui/react"
import { useCategories } from '../../../providers/CategoriesProvider';
import { Link } from 'react-router-dom';

export default function AllCategories() {

    const { categories} = useCategories();
    console.log(categories)
    return (
        <>
            <Container maxW='8xl'>
                <VStack spacing='5' my={10}>
                    <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Our Categories</Text>
                    <Text>Our wonderful categories for you to explore.</Text>

                    <SimpleGrid alignItems='center' justifyContent='center' w='100%' columns={[3, 3, 3, 6]} spacing={[4, 4, 6, 8]}>
                        {categories.map((cat, index) => {
                            return (
                                <Link key={index} to={`/categories/${cat.slug}`}>
                                    <VStack w='100%' spacing='2'>
                                        <AspectRatio ratio={1.04} w='100%'>
                                            <img src={cat.image} alt='category' />
                                        </AspectRatio>
                                        <Text as='h2' className='customfont' fontSize={[14, 18, 18, 20, 22]}>
                                            {cat.name}
                                        </Text>
                                    </VStack>
                                </Link>
                            )
                        })}
                    </SimpleGrid>
                </VStack>
            </Container>
        </>
    )
}
