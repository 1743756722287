import {
    Container,
    SimpleGrid,
    Box,
    Text,
    Button,
    HStack,
    Image,
    Flex,
    InputGroup,
    InputLeftElement,
    Divider,
    Input,
    VStack
} from '@chakra-ui/react'

import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { GrUndo } from "react-icons/gr";
import { IoBagCheckOutline, IoSearchOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import OutlinedButton from './OutlinedButton';
import { useAuth } from '../../providers/AuthProvider';
export default function MobileNav({ onClose }) {

    const [viewShop, setviewShop] = useState(false)
    const [viewCollections, setviewCollections] = useState(false)
    const [viewCategory, setviewCategory] = useState(false)
    const [viewAccessories, setviewAccessories] = useState(false)
    const { logged, logout } = useAuth()
    const [viewprofile, setviewProfile] = useState(false)

    return (
        <Container
            transition={'all ease-in 300ms'}
            h={'100vh'}
            w={'100%'}
            position='fixed'
            // py={4}
            // zIndex={99} 
            // top={0}
            // left={0}
            overflowY={'auto'}
            bg={'white'}
        >
            <Box h='100%' w={'100%'} position={'relative'}>
                <SimpleGrid columns={[1, 1, 1, 1]} spacing='4'>
                    <Box />
                    <HStack spacing='4'>
                        {/* <GrUndo size={28} onClick={() => // props.setopenMenu(false)
                        onClose()} /> */}
                        {/* <IoMdArrowBack size={28} onClick={() => // props.setopenMenu(false)
                        onClose()} /> */}
                        <IoMdArrowBack size={28} onClick={() => onClose()} />
                        <img src='/logo-dark.png' alt='' width='120px' />
                    </HStack>
                    {/* <GrUndo size={20} onClick={() => // props.setopenMenu(false)
                    onClose()} /> */}
                    {/* <IoSearchOutline size={20} /> */}
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'  >
                            <IoSearchOutline size={18} />
                        </InputLeftElement>
                        <Input
                            autoFocus={false}
                            variant='outline'
                            outline='none'
                            border='none'
                            borderBottom='1px solid #E5E5E5'
                            placeholder='Search'
                        />
                    </InputGroup>
                    <Link to={`/`} onClick={() => // props.setopenMenu(false)
                        onClose()} >
                        <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                            my={2}
                            w={'100%'}
                            rounded={0}
                            justifyContent={'flex-start'}
                        // borderBottom={'1px solid #E5E5E5'}
                        >
                            Home
                        </Button>
                    </Link>
                    <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'flex-start'}
                        // borderBottom={'1px solid #E5E5E5'}
                        onClick={() => {
                            setviewShop(!viewShop)
                            setviewCollections(false)
                            setviewCategory(false)
                            setviewAccessories(false)
                        }}
                    >
                        Shop
                    </Button>
                    {viewShop ?
                        <VStack
                            pl={6}
                            w={'100%'}
                        >
                            <Button
                                variant='ghost'
                                borderRadius='lg'
                                color={'black'}
                                fontSize={'lg'}
                                my={2}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                // borderBottom={'1px solid #E5E5E5'}
                                onClick={() => {
                                    setviewCollections(!viewCollections)
                                    setviewCategory(false)
                                    setviewAccessories(false)
                                }}
                            >
                                Collections
                            </Button>
                            {
                                viewCollections ?
                                    <VStack
                                        pl={8}
                                        w={'100%'}
                                    >
                                        <Box
                                            onClick={() => {
                                                // props.setopenMenu(false)
                                                onClose()
                                                setviewCollections(false)
                                                setviewShop(false)
                                                setviewCategory(false)
                                                setviewAccessories(false)
                                            }}
                                            as={Link}
                                            to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                            my={2}
                                            py={2}
                                            pl={3}
                                            w={'100%'}
                                            rounded={0}
                                            justifyContent={'flex-start'}
                                        // borderBottom={'1px solid #E5E5E5'}
                                        >
                                            Festival 2023
                                        </Box>
                                        <Box
                                            onClick={() => {
                                                // props.setopenMenu(false)
                                                onClose()
                                                setviewCollections(false)
                                                setviewShop(false)
                                                setviewCategory(false)
                                                setviewAccessories(false)
                                            }}
                                            as={Link}
                                            to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                            my={2}
                                            py={2}
                                            pl={3}
                                            w={'100%'}
                                            rounded={0}
                                            justifyContent={'flex-start'}
                                        // borderBottom={'1px solid #E5E5E5'}
                                        >
                                            Spring 2024
                                        </Box>
                                        <Box
                                            as={Link}
                                            to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                            my={2}
                                            py={2}
                                            pl={3}
                                            onClick={() => {
                                                // props.setopenMenu(false)
                                                onClose()
                                                setviewCollections(false)
                                                setviewShop(false)
                                                setviewCategory(false)
                                                setviewAccessories(false)
                                            }}
                                            w={'100%'}
                                            rounded={0}
                                            justifyContent={'flex-start'}
                                        // borderBottom={'1px solid #E5E5E5'}
                                        >
                                            Festival 2024
                                        </Box>
                                    </VStack> : null
                            }
                            <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                                my={2}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                // borderBottom={'1px solid #E5E5E5'}
                                onClick={() => {
                                    setviewCollections(false)
                                    setviewCategory(!viewCategory)
                                    setviewAccessories(false)
                                }}
                            >
                                Category
                            </Button>
                            {
                                viewCategory ? <VStack pl={8} w={'100%'} justifyContent={'center'} alignItems={'flex-start'}>
                                    <OutlinedButton rounded='6' width='70px' color={'#054E47'}
                                        bg='none'

                                        // border={'1px solid #054E47'}
                                        _hover={
                                            {
                                                bg: 'none'
                                            }
                                        }
                                        py='2'
                                        fontSize='lg'
                                    >
                                        GIRLS</OutlinedButton>
                                    <Box
                                        onClick={() => {
                                            // props.setopenMenu(false)
                                            onClose()
                                            setviewCollections(false)
                                            setviewShop(false)
                                            setviewCategory(false)
                                            setviewAccessories(false)
                                        }}
                                        as={Link}
                                        to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                        my={2}
                                        py={2}
                                        pl={3}
                                        w={'100%'}
                                        rounded={0}
                                        justifyContent={'flex-start'}
                                    // borderBottom={'1px solid #E5E5E5'}
                                    >
                                        Party Wear
                                    </Box>
                                    <Box
                                        onClick={() => {
                                            // props.setopenMenu(false)
                                            onClose()
                                            setviewCollections(false)
                                            setviewShop(false)
                                            setviewCategory(false)
                                            setviewAccessories(false)
                                        }}
                                        as={Link}
                                        to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                        my={2}
                                        py={2}
                                        pl={3}
                                        w={'100%'}
                                        rounded={0}
                                        justifyContent={'flex-start'}
                                    // borderBottom={'1px solid #E5E5E5'}
                                    >
                                        Co-ord sets
                                    </Box>
                                    <Box
                                        as={Link}
                                        to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                        my={2}
                                        py={2}
                                        pl={3}
                                        onClick={() => {
                                            // props.setopenMenu(false)
                                            onClose()
                                            setviewCollections(false)
                                            setviewShop(false)
                                            setviewCategory(false)
                                            setviewAccessories(false)
                                        }}
                                        w={'100%'}
                                        rounded={0}
                                        justifyContent={'flex-start'}
                                    // borderBottom={'1px solid #E5E5E5'}
                                    >
                                        Lehenga Set
                                    </Box>
                                    <OutlinedButton rounded='6' width='70px' color={'#054E47'}
                                        bg='none'


                                        border={'1px solid #054E47'}
                                        _hover={
                                            {
                                                bg: 'none'
                                            }
                                        }
                                        py='2'
                                        fontSize='lg'
                                    >
                                        BOYS</OutlinedButton>
                                    <Box
                                        onClick={() => {
                                            // props.setopenMenu(false)
                                            onClose()
                                            setviewCollections(false)
                                            setviewShop(false)
                                            setviewCategory(false)
                                            setviewAccessories(false)
                                        }}
                                        as={Link}
                                        to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                        my={2}
                                        py={2}
                                        pl={3}
                                        w={'100%'}
                                        rounded={0}
                                        justifyContent={'flex-start'}
                                    // borderBottom={'1px solid #E5E5E5'}
                                    >
                                        Lorem ipsum
                                    </Box>
                                    <Box
                                        as={Link}
                                        to={`/festival`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                        my={2}
                                        py={2}
                                        pl={3}
                                        onClick={() => {
                                            // props.setopenMenu(false)
                                            onClose()
                                            setviewCollections(false)
                                            setviewShop(false)
                                            setviewCategory(false)
                                            setviewAccessories(false)
                                        }}
                                        w={'100%'}
                                        rounded={0}
                                        justifyContent={'flex-start'}
                                    // borderBottom={'1px solid #E5E5E5'}
                                    >
                                        Lorem ipsum
                                    </Box>

                                </VStack> : null
                            }
                            <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                                my={2}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                // borderBottom={'1px solid #E5E5E5'}
                                onClick={() => {
                                    setviewCollections(false)
                                    setviewCategory(false)
                                    setviewAccessories(!viewAccessories)
                                }}
                            >

                                Accessories
                            </Button>
                            {
                                viewAccessories ? <VStack pl={8} w={'100%'}>
                                    <Box
                                        onClick={() => {
                                            // props.setopenMenu(false)
                                            onClose()
                                            setviewCollections(false)
                                            setviewShop(false)
                                            setviewCategory(false)
                                            setviewAccessories(false)
                                        }}
                                        as={Link}
                                        to={`/`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                        my={2}
                                        py={2}
                                        pl={3}
                                        w={'100%'}
                                        rounded={0}
                                        justifyContent={'flex-start'}
                                    // borderBottom={'1px solid #E5E5E5'}
                                    >
                                        Hair Accessories
                                    </Box>
                                    <Box
                                        onClick={() => {
                                            // props.setopenMenu(false)
                                            onClose()
                                            setviewCollections(false)
                                            setviewShop(false)
                                            setviewCategory(false)
                                            setviewAccessories(false)
                                        }}
                                        as={Link}
                                        to={`/`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                        my={2}
                                        py={2}
                                        pl={3}
                                        w={'100%'}
                                        rounded={0}
                                        justifyContent={'flex-start'}
                                    // borderBottom={'1px solid #E5E5E5'}
                                    >
                                        Shoes
                                    </Box>
                                </VStack> : null
                            }
                            <Box
                                as={Link}
                                to={`/`} onClick={() => // props.setopenMenu(false)
                                    onClose()} borderRadius='lg' color={'black'} fontSize={'lg'}
                                my={2}
                                py={2}
                                pl={3}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                            // borderBottom={'1px solid #E5E5E5'}
                            >
                                Celebrity Closet
                            </Box>
                        </VStack> : null}


                    <Link to={`/about`} onClick={() => // props.setopenMenu(false)
                        onClose()} >
                        <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                            my={2}
                            w={'100%'}
                            rounded={0}
                            justifyContent={'flex-start'}
                        // borderBottom={'1px solid #E5E5E5'}
                        >
                            About Us
                        </Button>
                    </Link>

                    <Link to={`/contact`} onClick={() => // props.setopenMenu(false)
                        onClose()} >
                        <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                            my={2}
                            w={'100%'}
                            rounded={0}
                            justifyContent={'flex-start'}
                            // borderBottom={'1px solid #E5E5E5'}
                            alignItems={'center'}
                        >
                            Contact Us
                        </Button>
                    </Link>

                    <Divider w='90%' />

                    <Link to={`/cart`} onClick={() => // props.setopenMenu(false)
                        onClose()} >
                        <Button variant='ghost' borderRadius='lg' color={'black'}
                            my={2}
                            w={'100%'}
                            rounded={0}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        // borderBottom={'1px solid #E5E5E5'}
                        >
                            <Text fontSize={'lg'}>Shopping Cart</Text>
                            <HStack
                                ml={5}
                                gap={1}
                                justifyContent='flex-end' alignItems={'center'}>
                                {/* <Image src={'/cart-icon-black.png'} alt='' /> */}
                                <img src='/shop-bag.png' width='20' />
                                <Flex
                                    borderRadius={'50%'}
                                    bg={'black'}
                                    color={'white'}
                                    w={'18px'}
                                    h={'18px'}
                                    justifyContent={'center'}
                                    alignItems='center'
                                    fontSize={'12px'}
                                >
                                    0
                                </Flex>
                            </HStack>
                        </Button>
                    </Link>
                    <Link to={`/cart`} onClick={() => // props.setopenMenu(false)
                        onClose()} >
                        <Button variant='ghost' borderRadius='lg' color={'black'}
                            my={2}
                            w={'100%'}
                            rounded={0}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        // borderBottom={'1px solid #E5E5E5'}
                        >
                            <Text fontSize={'lg'}>Wishlist</Text>
                            <HStack
                                ml={5}
                                gap={1}
                                justifyContent='flex-end' alignItems={'center'}>
                                {/* <Image src={'/cart-icon-black.png'} alt='' /> */}
                                {/* <IoBagCheckOutline color='#1c1c1c' size='24px' /> */}
                                <img src='/shop-bag.png' width='20' />
                                <Flex
                                    borderRadius={'50%'}
                                    bg={'black'}
                                    color={'white'}
                                    w={'18px'}
                                    h={'18px'}
                                    justifyContent={'center'}
                                    alignItems='center'
                                    fontSize={'12px'}
                                >
                                    0
                                </Flex>
                            </HStack>
                        </Button>
                    </Link>
                    <Divider w='90%' />
                    {
                        logged ?
                            <Button
                                variant='ghost'
                                borderRadius='lg'
                                color={'black'}
                                fontSize={'lg'}
                                my={2}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                borderBottom={'1px solid #E5E5E5'}
                                onClick={() => {
                                    setviewProfile(!viewprofile)
                                    setviewCollections(false)
                                    setviewCategory(false)
                                    setviewAccessories(false)
                                }}
                            >

                                My Account
                            </Button>

                            :
                            <Link to={`/login`} onClick={() => // props.setopenMenu(false)
                                onClose()} >
                                <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                                    my={2}
                                    w={'100%'}
                                    rounded={0}
                                    justifyContent={'flex-start'}
                                // borderBottom={'1px solid #E5E5E5'}
                                >
                                    Sign In
                                </Button>
                            </Link>
                    }
                    {
                        viewprofile && <VStack pl={8} w={'100%'}>
                            <Box
                                onClick={() => {
                                    // props.setopenMenu(false)
                                    onClose()
                                    setviewCollections(false)
                                    setviewShop(false)
                                    setviewCategory(false)
                                    setviewAccessories(false)
                                    setviewProfile(false)
                                }}
                                as={Link}
                                to={`/`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                my={2}
                                py={2}
                                pl={3}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                borderBottom={'1px solid #E5E5E5'}
                            >
                                My Profile
                            </Box>
                            <Box
                                onClick={() => {
                                    // props.setopenMenu(false)
                                    onClose()
                                    setviewCollections(false)
                                    setviewShop(false)
                                    setviewCategory(false)
                                    setviewAccessories(false)
                                    setviewProfile(false)
                                }}
                                as={Link}
                                to={`/`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                my={2}
                                py={2}
                                pl={3}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                borderBottom={'1px solid #E5E5E5'}
                            >
                                Wishlist
                            </Box>
                            <Box
                                onClick={() => {
                                    // props.setopenMenu(false)
                                    onClose()
                                    setviewCollections(false)
                                    setviewShop(false)
                                    setviewCategory(false)
                                    setviewAccessories(false)
                                    setviewProfile(false)
                                    setviewProfile(false)
                                }}
                                as={Link}
                                to={`/`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                my={2}
                                py={2}
                                pl={3}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                borderBottom={'1px solid #E5E5E5'}
                            >
                                My Orders
                            </Box>
                            <Box
                                onClick={() => {
                                    // props.setopenMenu(false)
                                    onClose()
                                    setviewCollections(false)
                                    setviewShop(false)
                                    setviewCategory(false)
                                    setviewAccessories(false)
                                    setviewProfile(false)
                                    logout()
                                }}
                                as={Link}
                                to={`/`} borderRadius='lg' color={'black'} fontSize={'lg'}
                                my={2}
                                py={2}
                                pl={3}
                                w={'100%'}
                                rounded={0}
                                justifyContent={'flex-start'}
                                borderBottom={'1px solid #E5E5E5'}
                            >
                                Logout
                            </Box>
                        </VStack>
                    }

                    <HStack mt='4' pb={5}>
                        <Image src='/facebook.png' alt='' />
                        <Image src='/instagram.png' alt='' />
                    </HStack>
                </SimpleGrid>
            </Box>
        </Container>
    )
}
