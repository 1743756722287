import React from 'react'
import AllCollections from './AllCollections'
import { Container, VStack, Text } from '@chakra-ui/react'
export default function CollectionHome() {


    return (
        <>
            <Container maxW='8xl'>
                <VStack alignItems={'center'} w={'100%'} my={6}>
                    <Text fontWeight={800} fontSize={'4xl'}>Top Collections</Text>
                    <Text textAlign={'center'} color={'#736e6e'}>Follow the most popular trends and get exclusive items from coco shop.</Text>
                </VStack>

                <AllCollections />

            </Container>
        </>
    )

}
