import React from 'react'
import { HStack, Image } from '@chakra-ui/react'
const Reviews = () => {
    return (
        <HStack
            w={'80%'}
            h={'70%'}
            justifyContent={'space-evenly'} alignItems={'center'}>
            <Image src='/home_row2_1.png' alt='' h={'100%'}
                w={'30%'}
            />
            <Image src='/home_row2_1.png' alt='' h={'100%'}
                w={'30%'}
            />
            <Image src='/home_row2_1.png' alt='' h={'100%'}
                w={'30%'}
            />
        </HStack>
    )
}

export default Reviews