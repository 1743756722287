import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from "@chakra-ui/react"
import App from './App';
import './index.css';
import 'react-multi-carousel/lib/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'md',
      },
    },
  },
  colors: {
    "primary": {
      "50": "#f7f7f7",
      "100": "#e0e0e0",
      "200": "#c5c5c5",
      "300": "#a6a6a6",
      "400": "#949494",
      "500": "#7d7d7d",
      "600": "#696969",
      "700": "#545454",
      "800": "#474747",
      "900": "#333333"
    }
  },
  styles: {
    global: {
      'html, body': {
        bg: '#efefef',
      },
    },
  },
})

// removing logs in prod 
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);


reportWebVitals();
