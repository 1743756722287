import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box, HStack, FormLabel, Input, Button, Flex, Image, Text, VStack
} from '@chakra-ui/react';
import React from 'react'
import { RiDeleteBin7Fill } from 'react-icons/ri';
// import { useCarts } from '../../providers/CartProvider';
import { Link } from 'react-router-dom';
import PlaceSale from '../common/PlaceSale';
import OutlinedButton from '../common/OutlinedButton';

export default function Cart() {

    // var { carts, totalPrice, addCarts } = useCarts();

    // const calculateDiscount = (price, sellPrice) => {
    //     return Math.round(((price - sellPrice) / price) * 100);
    // };

    const cart_table_data = [
        {
            imgUrl: './home_row2_1.png',
            Price: 12000,
            name: 'lorum dollar',
            quantity: 2,
            size: 'XS'
        },
        {
            imgUrl: './home_row2_2.png',
            Price: 12000,
            name: 'lorum dollar',
            quantity: 2,
            size: 'XS'
        },
        {
            imgUrl: './home_row2_3.png',
            Price: 12000,
            name: 'lorum dollar',
            quantity: 2,
            size: 'XS'
        }
    ]

    return (
        <>
            <Flex
                maxW={'100%'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                bg={'#FFFFFF'}
                display={['none', 'none', 'flex', 'flex']}
            >
                <Image src={'./contact_banner.png'} alt=''
                    h={'45vh'}
                    w={'100%'} />
                <Flex
                    w='80%'
                    my={10}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <TableContainer
                        minH={'15vh'}
                        w={'100%'}
                    >
                        <Table>
                            <Thead
                                h={'10vh'}
                                w={'100%'}
                            >
                                <Tr>
                                    <Th
                                        className='customfont'
                                        fontSize={'xl'}
                                        color={'#000000'}
                                        w={'35%'}
                                    >
                                        Product
                                    </Th>
                                    <Th
                                        className='customfont'
                                        fontSize={'xl'}
                                        color={'#000000'}
                                    >
                                        Price
                                    </Th>
                                    <Th
                                        className='customfont'
                                        fontSize={'xl'}
                                        color={'#000000'}
                                    >
                                        Quantity
                                    </Th>
                                    <Th
                                        className='customfont'
                                        fontSize={'xl'}
                                        color={'#000000'}
                                    >
                                        Sub total
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody w={'100%'}
                                borderY={'1px solid #D9D9D9'}
                            >
                                {
                                    cart_table_data.map((item, index) => {
                                        return <Tr
                                            key={index}
                                            h={'5vh'}
                                            w={'100%'}>
                                            <Th
                                                h={'100%'}
                                                w={'35%'}
                                                className='customfont'>
                                                <HStack
                                                    gap={7}
                                                    h={'100%'}
                                                    w={'100%'}>
                                                    <Image src={item.imgUrl} alt=''
                                                        h={'100%'}
                                                        w={'30%'} />
                                                    <Text
                                                        fontSize={'medium'}
                                                        color={'#000000'}
                                                        fontWeight={500}
                                                    >
                                                        {item.name}
                                                    </Text>
                                                </HStack>

                                            </Th>
                                            <Th
                                                fontSize={'medium'}
                                                color={'#000000'}
                                                fontWeight={500}
                                                className='customfont'
                                            >
                                                Rs.{item.Price}
                                            </Th>
                                            <Th>
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'space-between'}
                                                    alignItems={'center'}
                                                    border={'1px solid #9F9F9F'}
                                                    borderRadius={'md'}
                                                    w={'100px'}
                                                    className='customfont'
                                                >
                                                    <Button
                                                        bg={'none'}
                                                        color={'#000000'}
                                                        fontSize={'md'}
                                                        _hover={
                                                            { bg: 'none' }
                                                        }
                                                    >
                                                        +
                                                    </Button>
                                                    <Text
                                                        fontSize={'md'}
                                                        color={'#000000'}
                                                        h={'100%'}
                                                        textAlign={'center'}
                                                    >
                                                        {item.quantity}
                                                    </Text>
                                                    <Button
                                                        bg={'none'}
                                                        color={'#000000'}
                                                        fontSize={'md'}
                                                        _hover={
                                                            { bg: 'none' }
                                                        }
                                                    >
                                                        -
                                                    </Button>
                                                </Box>
                                            </Th>
                                            <Th
                                            >
                                                <HStack gap={5}>
                                                    <Text
                                                        fontSize={'medium'}
                                                        color={'#000000'}
                                                        fontWeight={500}
                                                        className='customfont'
                                                    >
                                                        Rs.{item.Price}
                                                    </Text>
                                                    <RiDeleteBin7Fill size={20} />
                                                </HStack>
                                            </Th>
                                        </Tr>
                                    })
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
                <HStack
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    my={5}
                    w={'80%'}
                >
                    <Flex
                        w={'60%'}
                        h={'100%'}
                        justifyContent={'flex-start'} alignItems={'flex-start'}
                        gap={8}>
                        <VStack
                            w={'60%'}
                            h={'90%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <FormLabel
                                color={'#000000'}
                                fontSize={'xl'}
                            >Coupon Code</FormLabel>
                            <Input
                                type='text'
                                border={'none'}
                                borderRadius={0}
                                borderBottom={'1px solid #000000'}
                                fontSize={'xl'}
                            />
                        </VStack>
                        <Button
                            bg={'none'}
                            color={'#000000'}
                            _hover={
                                { bg: 'none' }
                            }
                            w={'20%'}
                            h={'70%'}
                            fontSize={'xl'}
                        >Apply Coupon</Button>
                    </Flex>
                    <Flex w={'30%'}
                        h={'90%'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        gap={'6'}
                    >
                        <Button
                            bg={'none'}
                            color={'#000000'}
                            border={'none'}
                            borderBottom={'1px solid #000000'}
                            borderRadius={0}
                            _hover={
                                { bg: 'none' }
                            }
                            w={'45%'}
                            h={'70%'}
                            fontSize={'xl'}
                        >UPDATE CART</Button>
                        <Button
                            bg={'none'}
                            color={'#000000'}
                            _hover={
                                { bg: 'none' }
                            }
                            w={'45%'}
                            h={'70%'}
                            borderRadius={0}
                            border={'none'}
                            borderBottom={'1px solid #000000'}
                            fontSize={'xl'}
                        >CLEAR CART</Button>
                    </Flex>
                </HStack>
                <VStack
                    w={'85%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    minH={'50vh'}
                    p={6}
                    my={10}
                    py={12}
                    border={'2px solid black'}
                >
                    <Flex
                        w={'90%'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        my={2}
                    >
                        <Text
                            fontSize={'xx-large'}
                            color={'#000000'}
                            w={'50%'}
                        >Cart totals</Text>
                        <Text
                            fontSize={'xx-large'}
                            color={'#000000'}
                            w={'25%'}
                        >SUB TOTAL</Text>
                        <Text
                            fontSize={'xx-large'}
                            color={'#000000'}
                            w={'25%'}
                            textAlign={'right'}
                        >₹1,050,000.00</Text>
                    </Flex>
                    <Text

                        w={'90%'}
                        textAlign={'left'}
                        fontSize={'xx-large'}
                        color={'#000000'}
                    >
                        SHIPPING
                    </Text>
                    <VStack

                        w={'90%'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                        borderY={'1px solid #000000'}
                        gap={4}
                        my={3}
                        py={10}
                    >
                        <Text
                            w={'100%'}
                            fontSize={'lg'}
                            color={'#000000'}
                            fontWeight={700}
                        >
                            Shipping to Maharashtra
                        </Text>
                        <Text
                            w={'100%'}
                            fontSize={'lg'}
                            color={'#000000'}
                            fontWeight={600}
                        >
                            Personal Details & Address
                        </Text>
                        <Text
                            w={'45%'}
                            fontSize={'lg'}
                            color={'#8A8A8A'}
                        >
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </Text>
                    </VStack>


                    <HStack w='98%'
                        justifyContent='space-between'
                        px={10}
                        my={2}
                        pl={'50%'}
                    >
                        <Text
                            // w={'25%'}
                            textAlign={'left'}
                            fontSize={'xx-large'}
                            color={'#000000'}
                        >
                            TOTAL
                        </Text>
                        <Text
                            fontSize={'xx-large'}
                            color={'#000000'}
                            // w={'25%'}
                            textAlign={'right'}
                        >₹1,050,000.00</Text>
                    </HStack>

                    <HStack w='100%'
                        justifyContent='end'
                        px={10}>
                        <OutlinedButton
                            rounded='0'
                            type='submit'
                            height='55px'
                            width='240px'
                            bg={'#054E47'}
                            color={'#FFFFFF'}
                            _hover={
                                { bg: '#054E47' }
                            }
                        >
                            <Link to={'/checkout'}>
                                PROCEED CHECKOUT
                            </Link>
                        </OutlinedButton>
                    </HStack>

                </VStack>
                <PlaceSale />
            </Flex>
            <Flex gap={3}
                w='100%'
                px={6}
                py={4}
                flexDirection={'column'}
                justifyContent={'space-between'}
                bg={'#FFFFFF'}
                display={['flex', 'flex', 'none', 'none']}
            >
                <Text fontSize='x-large' w={'100%'}
                    textAlign={'left'}
                >CART</Text>
                <TableContainer
                    w={'100%'}
                    pb={'20vh'}
                    borderBottom={'1px solid #E5E5E5'}
                >
                    <Table>
                        <Thead w={'100%'}
                            justifyContent={'space-between'}
                        >
                            <Tr p={0}
                                justifyContent={'space-between'}>
                                <Td
                                    py={2}
                                    px={1}
                                    className='customfont'
                                    fontSize='sm'
                                    color={'black'}
                                >Product</Td>
                                <Td
                                    py={2}
                                    className='customfont'
                                    fontSize='sm'
                                    color={'black'}
                                >Quantity</Td>
                            </Tr>
                        </Thead>
                        <Tbody w={'100%'}
                            borderY={'1px solid #D9D9D9'}
                        >
                            {
                                cart_table_data.map((item, index) => {
                                    return <Tr key={index} w={'100%'}>
                                        <Td
                                            className='customfont'
                                            py={3}
                                            px={1}>
                                            <HStack
                                                gap={4}
                                                w={'100%'}>
                                                <Image src={item.imgUrl} alt=''
                                                    w={'30%'} />
                                                <VStack
                                                    gap={0}
                                                    h={'100%'}>
                                                    <Text
                                                        fontSize={'sm'}
                                                        color={'#000000'}
                                                        fontWeight={800}
                                                        w='100%'
                                                        textAlign={'left'}
                                                    >{item.name}</Text>
                                                    <Text
                                                        fontSize={'sm'}
                                                        color={'#000000'}
                                                        fontWeight={500}
                                                        w='100%'
                                                        textAlign={'left'}
                                                    >Size : {item.size}</Text>
                                                    <Text
                                                        color='#989898'
                                                        textAlign={'left'}
                                                        fontSize='sm'
                                                        w='100%'
                                                    >
                                                        Remove
                                                    </Text>
                                                </VStack>

                                            </HStack>

                                        </Td>

                                        <Td
                                        >
                                            <VStack gap={0} py={3} px={1}>
                                                <Text
                                                    fontSize={'sm'}
                                                    color={'#000000'}
                                                    fontWeight={500}
                                                    w='100%'
                                                    textAlign={'left'}
                                                    className='customfont'
                                                >Qty: {item.quantity}</Text>
                                                <Text
                                                    fontSize={'sm'}
                                                    color={'#000000'}
                                                    fontWeight={800}
                                                    className='customfont'
                                                >
                                                    ₹{item.Price}
                                                </Text>

                                            </VStack>

                                        </Td>
                                    </Tr>
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
                <HStack w='100%'
                    gap={0}
                    justifyContent='space-between'
                    py={2}
                >
                    <Input type='text'
                        w='100%'
                        borderRadius={0}
                        border={'none'}
                        borderBottom='1px solid #E5E5E5'
                        placeholder='Coupon Code'
                        _focusVisible={
                            {
                                border: 'none',
                                borderBottom: '1px solid #E5E5E5'
                            }
                        }
                    />
                    <OutlinedButton
                        bg='none'
                        color='black'
                        fontSize='sm'
                        borderRadius={0}
                        border={'none'}
                        borderBottom='1px solid #E5E5E5'
                        fontWeight={800}
                        _focusVisible={
                            {
                                border: 'none',
                                borderBottom: '1px solid #E5E5E5'
                            }
                        }
                    >
                        APPLY COUPON
                    </OutlinedButton>
                </HStack>
                <HStack w='100%'
                    justifyContent='space-between'
                >
                    <OutlinedButton
                        bg='none'
                        color='#8D8D8D'
                        fontSize='md'
                        borderRadius={0}
                        border={'none'}
                        _focusVisible={
                            {
                                border: 'none'
                            }
                        }
                        h='30px'
                    >
                        Update Cart
                    </OutlinedButton>
                    <OutlinedButton
                        bg='none'
                        color='#8D8D8D'
                        fontSize='md'
                        borderRadius={0}
                        border={'none'}
                        py='2'
                        _focusVisible={
                            {
                                border: 'none'
                            }
                        }
                        h='30px'
                    >
                        Clear Cart
                    </OutlinedButton>
                </HStack>
                <VStack
                    w={'100%'}
                    minH={'40vh'}
                    rounded={8}
                    gap={2}
                    my={3}
                    border={'1px solid black'}
                    // px={4}
                    p={5}
                >
                    <Text
                        color='black'
                        w={'100%'}
                        textAlign={'left'}
                        fontSize={'sm'}
                        fontWeight={800}>
                        CART TOTAL
                    </Text>
                    <HStack
                        w={'100%'}
                        justifyContent={'space-between'}>
                        <Text
                            color='black'
                            fontSize={'sm'}
                            fontWeight={500}>
                            Subtotal
                        </Text>
                        <Text
                            color='black'
                            fontSize={'md'}
                            fontWeight={800}>
                            ₹36000
                        </Text>
                    </HStack>
                    <VStack w={'100%'} justifyContent={'space-between'}
                        py={5}
                        borderY={'1px solid #E5E5E5'}
                    >
                        <HStack
                            w={'100%'}
                            justifyContent={'space-between'}>
                            <Text
                                color='black'
                                fontSize={'sm'}
                                fontWeight={500}>
                                SHIPPING
                            </Text>
                            <Text
                                color='black'
                                fontSize={'md'}
                                fontWeight={800}>
                                ₹3600
                            </Text>
                        </HStack>
                        <VStack gap={1}>
                            <Text
                                color='black'
                                w='100%'
                                textAlign='left'
                                fontSize={'md'} fontWeight={800}>
                                Shipping to Maharashtra
                            </Text>
                            <Text color='black'
                                w='100%'
                                textAlign='left'
                                fontSize={'sm'} fontWeight={500}>
                                Personal Details & Address
                            </Text>
                            <Text color='#8A8A8A'
                                w='100%'
                                textAlign='left'
                                fontSize={'sm'} fontWeight={500}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </Text>
                        </VStack>
                    </VStack>
                    <HStack
                        w={'100%'}
                        justifyContent={'space-between'}
                        my={2}
                    >
                        <Text
                            color='black'
                            fontSize={'sm'}
                            fontWeight={500}>
                            Total
                        </Text>
                        <Text color='black'
                            fontSize={'md'}
                            fontWeight={800}>
                            ₹36000
                        </Text>
                    </HStack>
                    <OutlinedButton
                        rounded='0'
                        type='submit'
                        width='100%' bg={'#054E47'}
                        py='6'
                        border='none'
                        fontSize='xl'
                        color='white'>
                        PROCEED CHECKOUT
                    </OutlinedButton>
                </VStack>
            </Flex>
        </>

    )
}