import React from 'react'
import { Text, HStack, Image } from '@chakra-ui/react'
const Description = () => {
    return (
        <>
            <Text h={'10%'}
                w={'70%'}
                textAlign={'left'}
                fontSize={'large'}
                color={'#000000'}
            >
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </Text>
            <Text h={'5%'}
                w={'70%'}
                textAlign={'right'}
                fontSize={'medium'}
                color={'#000000'}
            >
                view more
            </Text>
            <HStack
                w={'80%'}
                h={'65%'}
                justifyContent={'space-evenly'} alignItems={'center'}>
                <Image src='/home_row2_1.png' alt='' h={'100%'}
                    w={'30%'}
                />
                <Image src='/home_row2_1.png' alt='' h={'100%'}
                    w={'30%'}
                />
                <Image src='/home_row2_1.png' alt='' h={'100%'}
                    w={'30%'}
                />
            </HStack>
        </>
    )
}

export default Description