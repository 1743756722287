import { VStack, Text, Box, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiCircle, BiHeart } from 'react-icons/bi';
import { FaEye, FaHeart } from 'react-icons/fa6';
import { FaRupeeSign } from 'react-icons/fa';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom'

const ProductCard = (props) => {

    const [hovering, setHovering] = useState(false);

    return (
        <Box
            // bg='green'
            position='relative'
            // w={'100%'}
            // set minwidth to 200px if md or upper screen , if small set to 50% of the screen width 
            minW={['50%', '50%', '17%', '17%']}
            w={['90%', '90%', '90%', '90%']}
            // minW='200px'
            // bg='green'
            display={'flex'}
            flexDirection={'column'}
            minH={['350px', '400px', '475px', '475px']}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onTouchStart={() => setHovering(true)}
            onTouchEnd={() => setHovering(false)} b
            alignItems={'center'}
        >
            <Box
                // as={Link}
                // to={`/product/1`}
                height={['250px', '250px', '340px', '340px']}
                backgroundImage={props.item.imgUrl}
                backgroundSize={'cover'}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
                w={'100%'}
                position='relative'
            />
            <VStack
                position='absolute'
                bottom={['-10px', '10px', '20px', '35px']}
                height={hovering ? ['145px', '145px', '150px', '150px'] : ['120px', '120px', '130px', '120px']}
                overflow={'hidden'}
                bg={'white'}
                // bg={'red'}
                p='4'
                w={'100%'}
                justifyContent={'start'}
                alignItems={'start'}
                transform={hovering ? "translateY(0px)" : 'tranlateY(150px)'}
                transition="all 0.6s ease"
            >
                <Text
                    overflow='ellipsis'
                    height='65px'
                    minHeight='65px'
                    lineHeight='1.3'
                    fontSize={['sm', 'sm', 'md', 'md']}
                >{props.item.name}
                </Text>
                <Text
                    alignItems='baseline'
                    justifyContent='end'
                    h='100%'
                    fontWeight={'800'}
                    color={'#000000'}
                    fontSize={['sm', 'sm', 'lg', 'lg']}
                ><FaRupeeSign style={{ display: 'inline-block' }} size='14' /> {props.item.Price}
                </Text>
                <HStack justifyContent='space-between' w='100%'>
                    <Text as={Link} fontSize='12px' to='product/1'>Select Options</Text>
                    <HStack>
                        <FaEye />
                        <IoHeartOutline />
                    </HStack>
                </HStack>
            </VStack>
        </Box>
    )
}

export default ProductCard