import { Box, Stack, HStack, VStack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Navigate } from 'react-router-dom';
import Login from './Login';
import SignUp from './SignUp';

const AuthHome = () => {
    const navigate = useNavigate();

    const { logged, login } = useAuth();
    const [error, setError] = useState();

    const [selectedLogin, setselectedLogin] = useState(true);

    const [info, setinfo] = useState(null);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (values) => {
            // alert(JSON.stringify(values, null, 2));

            setinfo("Logging in...");
            var res = await login(values.email, values.password);
            setinfo(null);
            console.log(res);
            if (res === "err") {
                setError("Invalid Credentials !");
            } else {
                toast.success("Login Successfully !");
                navigate('/');
            }
        }

    });

    if (logged) {
        return <Navigate to='/' />
    }

    return (
        <VStack bg='white' minH={'100vh'} maxW={'100vw'}>
            <Box
                display={['none', 'none', 'block', 'block']}
                bg='red'
                h='65vh'
                maxh='80vh'
                w='100%'
                backgroundImage='./home_banner.png'
                backgroundSize='cover'
                backgroundPosition='center'
            />
            <Stack
                display={['none', 'none', 'flex', 'flex']}
                my='8'
                w={'90%'}
                direction={['column', 'column', 'row', 'row']}
                align="center"
                justify="space-evenly"
            >

                <Login />
                <SignUp />
            </Stack>

            <VStack
                display={['flex', 'flex', 'none', 'none']}
                w='100%'
                h='100%'
                align='center'
                justify='center'
            >
                <Text my='6' fontSize='3xl' fontWeight='bold'>My Account</Text>

                <HStack w='100%' justifyContent='center' spacing={10}>
                    {/* decoration and text should have more space between them */}
                    <Text decoration={selectedLogin ? 'underline' : 'none'} fontSize='2xl' onClick={() => setselectedLogin(true)}>LOG IN</Text>
                    <Text decoration={selectedLogin ? 'none' : 'underline'} fontSize='2xl' onClick={() => setselectedLogin(false)}>REGISTER</Text>
                </HStack>

                {selectedLogin ? <Login /> : <SignUp />}
            </VStack>


        </VStack>
    );
};

export default AuthHome;
