
import { Button, Flex, HStack, Box, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import OutlinedButton from '../../common/OutlinedButton';

const SignUp = () => {
    const { signUp, logged } = useAuth();
    const navigate = useNavigate();
    const [error] = useState();

    const [info, setinfo] = useState(null);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (values) => {
            alert(JSON.stringify(values, null, 2));

            try {
                setinfo("Creating Your account...");
                var res = await signUp(values.email, values.password);
                setinfo(null);
                navigate('/login');
            } catch (error) {
                setinfo(null);
                console.log(error);
                if (error.response.status === 400) {
                    console.log(error.response.data);
                    setinfo(error.response.data.error);
                } else {
                    setinfo("Something went wrong ! Please try again later.");
                }
            }
            // if (res === "err") {
            //     setError("Invalid Credentials !");
            // } else if (res === "success") {
            //     navigate('/login');
            // }
        }
    });

    if (logged) {
        return <Navigate to='/' />
    }

    return (
        <Flex minH='60vh' align="center" justify="center" >
            <Box bg="white" p={6} minW={'400px'} maxW={'450px'}  >
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                        <Text fontSize='3xl' as='h2' color={'#000000'}>Register</Text>

                        {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
                        {info && <Text color='blue'>{info}</Text>}
                        <FormControl>
                            <FormLabel htmlFor="email" color={'#000000'}>Your Email</FormLabel>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                required={true}
                                rounded='0'
                                size="lg"
                                border='1px solid #1c1c1c'
                                placeholder='Your Email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password" color={'#000000'}>Your Password</FormLabel>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                required={true}
                                rounded='0'
                                size="lg"
                                border='1px solid #1c1c1c'
                                placeholder='Your Password'
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </FormControl>
                        <Text>
                            Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.
                        </Text>
                        <OutlinedButton rounded='0' type='submit' width='150px' color={'#054E47'}>Register</OutlinedButton>

                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default SignUp;
