import React from 'react'
import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
export default function Footer() {

    return (
        <>
            <Box
                w='100%'
                // bg='#054E47'
                bg='#efefef'
                style={{ width: '100%' }}
                display={['flex', 'flex', 'none', 'none']}
                alignItems='center'
                justifyContent={'center'}
                pt='12'
            >
                <VStack
                    justifyContent={'center'}
                    alignItems={'center'}
                    w='100%'
                >
                    <HStack
                        w={'90%'}
                        justifyContent={'space-between'}
                        alignItems={'start'}
                        display={'flex'}
                        spacing='5'
                        flexDirection={['column', 'column', 'column', 'row']}
                    >
                        <VStack
                            w={['100%', '100%', '100%', '23%']}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={4}
                        >
                            <Text
                                fontSize={'20px'}
                                textAlign={'left'}
                                w={'100%'}
                            >USEFUL LINKS</Text>
                            <Link to={'/about'}>
                                <Text
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >About Us</Text>
                            </Link>
                            <Link to={'/contact'}>
                                <Text
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >Contact Us</Text>
                            </Link>
                        </VStack>
                        <VStack
                            w={['100%', '100%', '100%', '23%']}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={4}
                        >
                            <Text
                                fontSize={'20px'}
                                textAlign={'left'}
                                w={'100%'}
                                fontWeight='800'
                            >
                                CUSTOMER SERVICE
                            </Text>
                            <Link
                                to={'/returns-refunds'}
                            >
                                <Text
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >
                                    Returns & Refunds
                                </Text>
                            </Link>
                            <Link
                                to={'/terms-and-conditions'}
                            >
                                <Text
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >
                                    Terms & Conditions
                                </Text>
                            </Link>
                        </VStack>
                        <VStack
                            w={['100%', '100%', '100%', '35%']}
                            spacing={4}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <Text
                                fontSize={'20px'}
                                textAlign={'left'}
                                w={'100%'}
                            >
                                We're available by phone +123-456-789
                            </Text>
                            <Text
                                fontSize={'lg'}
                                textAlign={'left'}
                                w={'100%'}
                                fontWeight={400}
                            >
                                info@example.com
                            </Text>
                            <Text
                                fontSize={'lg'}
                                textAlign={'left'}
                                w={'100%'}
                                fontWeight={400}
                            >
                                Monday till Friday 10 AM to 7 PM
                            </Text>
                        </VStack>
                    </HStack>
                    <HStack
                        w='100%'
                        // bg='red'
                        px='4'
                        pb='6'
                        mt='4'
                        justifyContent={'flex-start'}
                        alignItems={'start'}
                    // spacing={10}
                    >
                        <Text
                            fontSize={'lg'}
                            fontWeight={400}
                        >
                            ©2024 COCO. All Rights Reserved
                        </Text>
                    </HStack>
                </VStack>
            </Box>

            <Box w='100%' bg='#054E47' style={{ width: '100%' }} display={['none', 'none', 'flex', 'flex']} alignItems='center' justifyContent={'center'} py='12'>
                <VStack w='100%' px={'12'} justifyContent={'center'} alignItems={'flex-start'}>

                    <HStack
                        w={'100%'}
                        justifyContent={'space-between'}
                        alignItems={'start'}
                        display={'flex'}
                        spacing='5'
                    >
                        <VStack
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={5}
                        >
                            <Text
                                color='white'
                                fontSize={'x-large'}
                                textAlign={'left'}
                                w={'100%'}
                            >USEFUL LINKS</Text>
                            <Link to={'/about'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >About Us</Text>
                            </Link>
                            <Link to={'/contact'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >Contact Us</Text>
                            </Link>
                        </VStack>
                        <VStack
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={5}
                        >
                            <Text
                                color='white'
                                fontSize={'x-large'}
                                textAlign={'left'}
                                w={'100%'}
                            >MY ACCOUNT</Text>
                            <Link to={'/track-order'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >Track My Order</Text>
                            </Link>
                            <Link to={'/privacy-policy'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >Privacy Policy</Text>
                            </Link>
                        </VStack>
                        <VStack
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={5}
                        >
                            <Text
                                color='white'
                                fontSize={'x-large'}
                                textAlign={'left'}
                                w={'100%'}
                            >CUSTOMER SERVICE</Text>
                            <Link to={'/returns-refunds'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >Returns & Refunds</Text>
                            </Link>
                            <Link to={'/terms-and-conditions'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >Terms & Conditions</Text>
                            </Link>
                        </VStack>
                        <VStack
                            spacing={5}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <Text
                                color='white'
                                fontSize={'x-large'}
                                textAlign={'left'}
                                w={'100%'}
                            >We're available by phone +123-456-789</Text>
                            <Text
                                color='white'
                                fontSize={'large'}
                                textAlign={'left'}
                                w={'100%'}
                                fontWeight={400}
                            >info@example.com</Text>
                            <Text
                                color='white'
                                fontSize={'large'}
                                textAlign={'left'}
                                w={'100%'}
                                fontWeight={400}
                            >Monday till Friday 10 AM to 7 PM</Text>
                        </VStack>
                    </HStack>
                    <HStack
                        my='8'
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                    >
                        <Text
                            color='white'
                            fontSize={'lg'}
                            fontWeight={400}
                        >©2024 COCO. All Rights Reserved</Text>
                    </HStack>
                </VStack>
            </Box>
        </>
    );
}
