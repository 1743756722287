import {
    Flex, Button, Text, VStack, HStack, Box, SimpleGrid, Container, IconButton,
    // useBreakpointValue 
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import HomeRowLargeCard from '../../common/HomeRowLargeCard'
import PlaceSale from '../../common/PlaceSale'
import ProductCard from '../../common/ProductCard'
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6'
import ReviewCard from '../../common/ReviewCard'
import OutlinedButton from '../../common/OutlinedButton'
import Carousel from "react-multi-carousel";


export default function Home() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            spacing: 10
        }
    };

    const home_row2_data = [
        {
            imgUrl: '/home_4_1.png',
            Price: 2000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_2.png',
            Price: 19000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_3.png',
            Price: 54000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_4.png',
            Price: 34000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_5.png',
            Price: 23999,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 2000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_2.png',
            Price: 23999,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_3.png',
            Price: 2000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
    ]

    const home_row4_data = [
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_2.png',
            Price: 9000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_3.png',
            Price: 12000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_4.png',
            Price: 1200,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_5.png',
            Price: 23000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        }
    ]

    const home_row3_data = [
        {
            imgUrl: '/home_row3_1.png',
            name: 'FESTIVAL WORLD'
        },
        {
            imgUrl: '/home_row3_2.png',
            name: 'EVERY MOOD'
        },
        {
            imgUrl: '/home_row3_3.png',
            name: 'SUMMER WEAR'
        },
        {
            imgUrl: '/home_row3_2.png',
            name: 'SAMPURNA'
        }
    ]

    // const slidesToShow = useBreakpointValue({ base: 2, md: 6 });
    // const settings = {
    //     mobileFirst: true,
    //     dots: true,
    //     arrows: false,
    //     // arrows: arrowShow,
    //     fade: true,
    //     infinite: true,
    //     autoplay: false,
    //     // speed: 500,
    //     // autoplaySpeed: 3000,
    //     // slidesToShow: slidesToShow,
    //     slidesToShow: 3,
    //     slidesToScroll: 2,
    //     responsive: [
    //         {
    //             breakpoint: 768, // Mobile (base: 0px - 768px)
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             },
    //         },
    //         {
    //             breakpoint: 1024, // Desktop (from 768px and above)
    //             settings: {
    //                 slidesToShow: 6,
    //                 slidesToScroll: 2,
    //             },
    //         },
    //     ],
    // };

    const desktopProductsScroll = useRef();
    const desktopCelebrityClosetScroll = useRef();

    return (
        <Flex
            flexDirection={'column'}
            justifyContent={'space-between'} alignItems={'center'}
            bg={'#FFFFFF'}
        >
            <Box
                backgroundImage='/home_banner.png'
                maxH='95vh'
                height='95vh'
                width='100%'
                backgroundSize='cover'
                backgroundPosition='center'
                backgroundRepeat='no-repeat'
            />

            <Container maxW='9xl' py='6'>

                <VStack w='100%' spacing='4'>

                    <Box
                        display={['flex', 'flex', 'flex', 'none']}
                        w='100%'
                        // bg='red'
                        h='350px'>
                        <Carousel responsive={responsive} arrows={false}>
                            {
                                home_row2_data.map((item, index) => {
                                    return <ProductCard item={item} key={index} />
                                })
                            }
                        </Carousel>
                    </Box>

                    <HStack
                        display={['none', 'none', 'none', 'flex']}
                        w='100%'
                    >
                        <IconButton
                            variant='ghost'
                            mx='20'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                const scrollAmount = desktopProductsScroll.current.clientWidth * 0.2;
                                desktopProductsScroll.current.scrollLeft += scrollAmount;
                                if (desktopProductsScroll.current.scrollLeft === desktopProductsScroll.current.scrollWidth - desktopProductsScroll.current.clientWidth) {
                                    desktopProductsScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowLeftLong size='24' />}
                        />
                        <HStack
                            scrollBehavior={'smooth'}
                            ref={desktopProductsScroll}
                            // bg='red'
                            w='100%'
                            overflowX='auto'
                            spacing='12'
                            style={{
                                // hide scrollbar 
                                scrollbarWidth: 'none',
                            }}
                        >
                            {
                                home_row2_data.map((item, index) => {
                                    return <ProductCard item={item} key={index} />
                                })
                            }
                        </HStack>
                        <IconButton
                            mx='20'
                            variant='ghost'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                // make the scroll to be 20% of the ref width 
                                const scrollAmount = desktopProductsScroll.current.clientWidth * 0.2;
                                desktopProductsScroll.current.scrollLeft += scrollAmount;
                                if (desktopProductsScroll.current.scrollLeft === desktopProductsScroll.current.scrollWidth - desktopProductsScroll.current.clientWidth) {
                                    desktopProductsScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowRightLong size='24' />}
                        />
                    </HStack>


                    {/* <HStack
                        display={['flex', 'flex', 'flex', 'none']}
                        w='100%'
                        p='6'
                        overflowX='auto'
                        spacing='4'
                        style={{
                            // hide scrollbar 
                            scrollbarWidth: 'none',
                        }}
                    >
                        {
                            home_row2_data.map((item, index) => {
                                return <ProductCard item={item} key={index} />
                            })
                        }
                    </HStack> */}

                    <SimpleGrid columns={[1, 1, 1, 1, 2]} w='100%' spacing={4} px={['0', '0', '40', '60px']}>
                        {
                            home_row3_data.map((item, index) => {
                                return <HomeRowLargeCard item={item} key={index} />
                            })
                        }
                    </SimpleGrid>


                    <HStack
                        display={['none', 'none', 'none', 'flex']}
                        w='100%'
                        alignItems='center'
                        justifyContent='center'
                        my='8'
                    >

                        <HStack
                            width='90%'
                            spacing='8'
                        >

                            <VStack minW='500px' spacing='8' h='100%' alignItems='start' justifyContent='start'>
                                <Text fontSize='3xl'>SUMMER WEAR</Text>
                                <Text fontSize='md'>Introducing ‘Silsila,’ a pre-festive collection that reverently captures the essence of Indian celebrations.
                                </Text>
                                <Text fontSize='md'>Introducing ‘Silsila,’ a pre-festive collection that reverently captures the essence of Indian celebrations.
                                </Text>
                                <Text fontSize='md'>Introducing ‘Silsila,’ a pre-festive collection that reverently captures the essence of Indian celebrations.
                                </Text>
                                <Text fontSize='md'>Introducing ‘Silsila,’ a pre-festive collection that reverently captures the essence of Indian celebrations.
                                </Text>
                            </VStack>

                            <ProductCard item={home_row2_data[0]} />
                            <ProductCard item={home_row2_data[1]} />
                            <ProductCard item={home_row2_data[2]} />
                        </HStack>
                    </HStack>

                    <HStack
                        display={['none', 'none', 'none', 'flex']}
                        width='90%'
                        spacing='8'
                    >
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[1]} />
                        <ProductCard item={home_row2_data[2]} />
                    </HStack>

                    {/* Mobile Screen  */}

                    <VStack display={['flex', 'flex', 'flex', 'none']} mt='10' spacing='8' h='100%' alignItems='start' justifyContent='start'>
                        <Text fontSize='3xl'>SUMMER WEAR</Text>
                        <Text fontSize='md'>Introducing Silsila, a pre-festive collection that reverently captures the essence of Indian celebrations.
                        </Text>
                        <Text fontSize='md'>Introducing Silsila, a pre-festive collection that reverently captures the essence of Indian celebrations.
                        </Text>
                        <Text fontSize='md'>Introducing Silsila, a pre-festive collection that reverently captures the essence of Indian celebrations.
                        </Text>
                        <Text fontSize='md'>Introducing Silsila, a pre-festive collection that reverently captures the essence of Indian celebrations.
                        </Text>
                    </VStack>

                    <SimpleGrid display={['flex', 'flex', 'flex', 'none']} w='100%' columns={2} spacing='4'>
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[1]} />
                        <ProductCard item={home_row2_data[2]} />
                    </SimpleGrid>

                    <OutlinedButton>
                        Explore More
                    </OutlinedButton>


                    <Text textAlign={'center'} fontSize={'xx-large'}
                        color={'#966754'}
                        my='6'
                    >
                        CELEBRITY CLOSET
                    </Text>

                    <HStack
                        display={['none', 'none', 'flex', 'flex']}
                        w='100%'
                    >
                        <IconButton
                            variant='ghost'
                            mx='20'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                const scrollAmount = desktopCelebrityClosetScroll.current.clientWidth * 0.2;
                                desktopCelebrityClosetScroll.current.scrollLeft += scrollAmount;
                                if (desktopCelebrityClosetScroll.current.scrollLeft === desktopCelebrityClosetScroll.current.scrollWidth - desktopCelebrityClosetScroll.current.clientWidth) {
                                    desktopCelebrityClosetScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowLeftLong size='24' />}
                        />
                        <HStack
                            scrollBehavior={'smooth'}
                            ref={desktopCelebrityClosetScroll}
                            // bg='red'
                            w='100%'
                            overflowX='auto'
                            spacing='12'
                            style={{
                                // hide scrollbar 
                                scrollbarWidth: 'none',
                            }}
                        >
                            {
                                home_row2_data.map((item, index) => {
                                    return <ProductCard item={item} key={index} />
                                })
                            }
                        </HStack>
                        <IconButton
                            mx='20'
                            variant='ghost'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                // make the scroll to be 20% of the ref width 
                                const scrollAmount = desktopCelebrityClosetScroll.current.clientWidth * 0.2;
                                desktopCelebrityClosetScroll.current.scrollLeft += scrollAmount;
                                if (desktopCelebrityClosetScroll.current.scrollLeft === desktopCelebrityClosetScroll.current.scrollWidth - desktopCelebrityClosetScroll.current.clientWidth) {
                                    desktopCelebrityClosetScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowRightLong size='24' />}
                        />
                    </HStack>

                    <HStack
                        display={['flex', 'flex', 'none', 'none']}
                        w='100%'
                        p='6'
                        overflowX='auto'
                        spacing='4'
                        style={{
                            // hide scrollbar 
                            scrollbarWidth: 'none',
                        }}
                    >
                        {
                            home_row2_data.map((item, index) => {
                                return <ProductCard item={item} key={index} />
                            })
                        }
                    </HStack>

                    {/* <PlaceSale /> */}

                </VStack>

            </Container>

            {/* <Flex
                display={['none', 'none', 'flex', 'flex']}
                bg={'#F8F3EF'}
                h={'70vh'}
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <VStack h={'90%'} w={'40%'}
                    justifyContent={'center'}
                    gap={8}
                    alignItems={'flex-start'}
                    pl={'20'}
                >
                    <Text
                        fontSize={'xx-large'}
                        fontWeight={700}
                        color={'#000000'}
                    >MORE THAN 1000+ BRANDS TRUSTS US</Text>
                    <Text
                        fontSize={'xl'}
                        color={'#000000'}
                    >Im a paragraph. Click here to add your own text and edit me.
                        Its easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.</Text>
                    <Button
                        bg={'#F8F3EF'}
                        color={'#000000'}
                        fontSize={'xl'}
                        _hover={
                            {
                                bg: '#F8F3EF'
                            }
                        }
                        border={'1px solid #4E6F524D'}
                        borderRadius={'30px'}
                        w={'50%'}
                    >Explore all reviews</Button>
                </VStack>
                <Flex h={'90%'} w={'45%'}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}
                    overflow='hidden'
                >
                    <VStack
                        w={'50%'}
                        mt={30}
                        gap={3}
                        // position='relative'
                        maxHeight='100%'
                    >
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard />
                    </VStack>
                    <VStack
                        w={'50%'}
                        gap={3}
                        alignItems={'center'}
                        justifyContent={'start'}
                    >
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard />
                    </VStack>
                </Flex>
            </Flex> */}

            {/* Mobile Screen  */}
            {/* <Flex
                display={['flex', 'flex', 'none', 'none']}
                bg={'#F8F3EF'}
                h={'70vh'}
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
                flexDirection={'column'}
            >
                <VStack
                    justifyContent={'center'}
                    spacing={4}
                    alignItems={'center'}
                >
                    <Text
                        fontSize={'xx-large'}
                        fontWeight={700}
                        color={'#054E47'}
                        textAlign={'center'}
                        w={'100%'}
                        my={5}
                    >MORE THAN 1000+<br /> BRANDS TRUSTS US</Text>
                    <Text
                        fontSize={'lg'}
                        color={'#000000'}
                        w={'100%'}
                        mb={3}
                        textAlign={'center'}
                    >Click here to add your own text and edit me.</Text>
                </VStack>
                <Flex
                    my={7}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}
                    overflow='hidden'
                    px={7}
                >
                    <VStack
                        w={'50%'}
                        mt={30}
                        gap={3}
                        // position='relative'
                        maxHeight='100%'
                    >
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard />
                    </VStack>
                    <VStack
                        w={'50%'}
                        gap={3}
                        alignItems={'center'}
                        justifyContent={'start'}
                    >
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard />
                    </VStack>
                </Flex>
            </Flex> */}
        </Flex>
    )
}
