import React from 'react'
import { Text } from '@chakra-ui/react'
const AdditinalInfo = () => {
    return (
        <>

            <Text h={'40%'}
                w={'70%'}
                textAlign={'left'}
                fontSize={'xl'}
                color={'#000000'}
            >
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                Lorem Ipsum is simply dummy text of the printing and
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </Text>
            <Text h={'10%'}
                w={'70%'}
                textAlign={'right'}
                fontSize={'medium'}
                color={'#000000'}
            >
                view more
            </Text></>
    )
}

export default AdditinalInfo