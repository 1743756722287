import React from 'react';
import { Container, Text, VStack } from "@chakra-ui/react"

export default function TermsAndConditions() {
    return (
        <Container maxW='8xl' my='6'>
            <VStack w='100%' alignItems='center' spacing='2'>
                <Text fontSize='4xl' fontWeight='800' className='customfont' textAlign='center'> Terms and Conditions</Text>

                <Text>Last updated on Jun 1 2024 </Text>

                <Text>
                    Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus sapien justo posuere volutpat curae eros class. Nam donec ullamcorper nascetur cras cras primis dolor. Facilisis vitae pretium fames platea metus massa fusce sapien montes. At commodo dapibus nec lacinia magna malesuada. Ante adipiscing pellentesque vehicula maecenas himenaeos habitasse. Sed volutpat euismod, tristique laoreet habitasse phasellus vehicula. Curae vehicula eleifend quis nunc curabitur pharetra vitae.

                    Feugiat at feugiat nisi nullam vulputate a quis cursus. Scelerisque sodales netus mauris tempus donec molestie quisque. Viverra augue phasellus convallis parturient aliquam dictum massa. Condimentum dapibus praesent velit nunc eget nunc efficitur sagittis ante. Pellentesque lacus odio, cursus odio odio ex etiam. Varius primis mollis id facilisi diam. Feugiat elementum dignissim pulvinar himenaeos finibus.

                    Praesent accumsan congue tempus tortor condimentum vel justo. Dignissim habitant nibh fusce montes habitant. Aenean tempus ac posuere habitant justo purus mus. Congue in magnis dolor nec, placerat fringilla. Ipsum orci bibendum convallis felis libero. Sapien augue parturient pharetra proin nunc himenaeos. Sagittis pellentesque taciti semper porta himenaeos. Lacinia scelerisque dictumst accumsan penatibus amet. Suspendisse dictum lobortis a primis, feugiat lectus ultricies turpis. Ex hendrerit eu lorem, curabitur condimentum fermentum.

                    Nibh massa nunc vestibulum aenean sodales porttitor dapibus. Et felis ipsum commodo et dignissim vestibulum eleifend. At mus quisque non sodales aliquet vehicula sapien. Cras lacinia sem aliquam tempus curabitur nullam potenti. Elementum primis phasellus cubilia eros massa; morbi nibh sagittis. Mauris quis sit eu quis felis pretium etiam habitasse. Rutrum sagittis est lectus ultrices proin fames ante.

                    Sociosqu congue amet lobortis condimentum ornare justo bibendum varius auctor. Mollis duis tortor proin metus consectetur nam. Vehicula adipiscing eu consequat blandit hac nulla. Dolor porttitor iaculis cursus potenti felis. Molestie nunc mattis sociosqu sociosqu sociosqu cras semper rhoncus. Nunc auctor porta per fusce ut habitant.

                    Dis laoreet scelerisque congue aliquam condimentum magnis arcu. Nam dapibus velit congue sem porta ultrices vulputate maximus. Ligula porttitor purus interdum montes donec diam. Imperdiet luctus pretium commodo a interdum imperdiet curae semper. Vehicula lacinia vehicula ridiculus donec tempor molestie, aliquet porta nec. Eleifend integer fringilla purus suscipit cursus cras proin. Porta interdum himenaeos viverra purus tortor.

                    Urna molestie sodales ullamcorper hac ex habitasse eu. Accumsan a amet fermentum libero potenti efficitur fermentum dui. Posuere tristique mauris volutpat viverra placerat taciti conubia bibendum. Fames enim scelerisque non laoreet potenti nostra efficitur est. Maximus tempus vel erat luctus rhoncus duis. Ut cubilia pretium rhoncus semper auctor. Ridiculus hendrerit pulvinar leo nostra; aliquam inceptos. Elementum dignissim leo blandit nascetur at praesent. Rhoncus natoque vitae efficitur taciti montes morbi.

                    Dui tristique montes ornare accumsan posuere posuere nisl primis. Laoreet viverra donec phasellus metus ex volutpat ac commodo sapien. Primis scelerisque aenean nascetur blandit velit donec accumsan. Massa dapibus at dignissim egestas hendrerit varius pretium curabitur ante. Molestie purus phasellus suscipit facilisi magnis accumsan fames ipsum. Orci metus fames turpis nibh tempor. Etiam per feugiat ex; volutpat etiam nibh orci efficitur. Efficitur sociosqu sollicitudin auctor tincidunt curae taciti varius ullamcorper viverra.

                    Netus dictumst curae pulvinar fusce; senectus erat. Facilisi malesuada phasellus fusce nisl habitasse mus libero laoreet velit. Magna pellentesque risus donec eu curae. Potenti tempor dictumst curabitur adipiscing lacus urna vulputate efficitur. Adipiscing gravida odio at aptent magnis ultrices. Cubilia adipiscing cubilia tortor ultrices cursus cras; lectus nostra amet. Duis dictum mattis rhoncus ridiculus cubilia massa dignissim.

                    Aenean egestas tempor ridiculus aliquet aliquam vulputate sodales. Scelerisque massa donec conubia at congue habitant fermentum. Mattis mollis tempor pretium pharetra bibendum pulvinar. Vestibulum nullam turpis eget molestie id aptent amet quisque. Nam egestas ultrices fermentum; sagittis sit quis. Gravida fringilla sagittis dignissim massa et. Taciti nostra mus fusce parturient lectus placerat odio amet. Primis sit nullam lectus euismod condimentum phasellus lectus. Netus faucibus augue consectetur fringilla bibendum amet felis facilisis consequat.

                    Tempus magna taciti vehicula volutpat ut eros. Mus habitasse dis efficitur ultrices ultrices conubia proin. Rhoncus tincidunt imperdiet purus fames quis felis. Nec pulvinar dictumst vel sollicitudin sagittis vestibulum. Placerat parturient lacus placerat mauris euismod dictumst facilisi. Fermentum pellentesque pulvinar et, aptent nostra mattis natoque. Nostra lobortis fusce imperdiet; tempor blandit metus habitasse integer nullam. Consectetur arcu tristique diam habitant dictum sed, molestie dapibus venenatis.

                    Mollis platea semper donec pellentesque tristique fusce. Tortor libero ridiculus molestie taciti magna laoreet. Est neque cursus volutpat feugiat nam libero purus quam pellentesque. Magnis fermentum accumsan ex vel accumsan pellentesque. Maecenas fermentum penatibus volutpat taciti et placerat. Massa per facilisis ligula dui hac sodales mus. Elementum amet porta mauris integer libero massa tristique lacinia. Potenti erat fringilla natoque platea vestibulum commodo dolor libero morbi. Ligula risus bibendum sociosqu vitae hendrerit varius sodales. Rutrum platea vel blandit fames consectetur montes habitant nisl.

                    Senectus mus porta habitant venenatis hac semper molestie. Risus libero commodo congue ridiculus a feugiat. Fringilla sem mollis congue etiam taciti; metus tortor. Nulla facilisis platea molestie dui morbi elementum nisi. Ante nisi nibh at proin sit ad! Hendrerit est netus arcu et fringilla adipiscing cursus hendrerit arcu. Nec himenaeos suspendisse metus pulvinar consequat bibendum.

                    Maecenas consequat fames suspendisse ligula primis suspendisse ante ac. Fermentum blandit vivamus; augue tempus mi porta. Vestibulum nascetur et purus proin phasellus amet ac? Parturient nascetur aptent natoque nec; mi at fusce. Nulla sodales dictum curae etiam volutpat, per conubia sit. Himenaeos venenatis duis ornare est sit blandit. Quis bibendum integer convallis nunc metus nulla vestibulum. Volutpat vel accumsan torquent elit urna cursus ex justo tellus. Senectus porta sollicitudin cras natoque placerat netus sociosqu natoque mus. Fusce conubia justo malesuada; tempus etiam condimentum mi.

                    Diam maecenas platea eros aenean class netus blandit adipiscing. Maecenas aliquet quis elementum euismod consequat ultrices donec. Nunc nisi donec tortor maximus dictumst. At per curae est vivamus dignissim. Mattis sit orci torquent dolor venenatis elit imperdiet purus. Parturient montes hendrerit metus sapien elementum imperdiet. Aquis inceptos purus odio, blandit sodales. Vel nisl primis odio enim vestibulum, mattis ultrices lacinia lacinia.
                </Text>

                    For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean M/S SHARDA PRINTERS, whose registered/operational office is 2D/15, INDUSTRIAL AREA, BALIDIH, BOKARO Bokaro JHARKHAND 827014 . "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
                    Your use of the website and/or purchase from us are governed by following Terms and Conditions:
                    The content of the pages of this website is subject to change without notice.
                    Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                    Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.
                    Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                    All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.
                    Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.
                    From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.
                    You may not create a link to our website from another website or document without M/S SHARDA PRINTERSs prior written consent.
                    Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India .
                    We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.
            </VStack>
        </Container>
    )
}