import { Text, Box } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeRowLargeCard(props) {

    return (
        <Box
            as={Link}
            to={'/festival'}
            position="relative"
            // width="300px"
            // height="400px"
            h={'45%'}
            minH={['210px', '210px', '280px', '350px']}
            overflow="hidden"
        // borderRadius="md"
        >
            {/* Background Image with zoom effect */}
            <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                // backgroundImage="url('https://via.placeholder.com/300x400')"
                backgroundImage={props.item.imgUrl}
                backgroundSize="cover"
                backgroundPosition="center"
                transition="transform 0.5s ease"
                _hover={{
                    transform: 'scale(1.1)', // Zoom in effect
                }}
            />

            {/* Text overlay (Not affected by zoom) */}
            <Box
                position="relative"
                top='50%'
                zIndex="1" // Keeps the text on top of the background
                // textAlign="center"
                color="white"
                // p={4}
                ml={6}
                mb={2}
                maxW='400px'
                spacing='1'
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'start'}
                alignItems={'start'}
            >
                <Text
                    w={'100%'}
                    color={'#FFFFFF'}
                >2024</Text>
                <Text
                    w={'100%'}
                    fontSize={['2xl', '2xl', '2xl', '4xl']}
                    color={'#FFFFFF'}
                >{props.item.name}</Text>
                <Text
                    textAlign='start'
                    w={'100%'}
                    fontSize={['xl', 'xl', 'xl', '2xl']}
                >Shop Now
                </Text>
                <Box w='30px' h='4px' bg='white' rounded='12px' />
            </Box>
        </Box>
    )

    // return (
    //     <Link
    //         to={'/festival'}
    //     >
    //         <Flex
    //             bgImage={props.item.imgUrl}
    //             backgroundSize={'cover'}
    //             backgroundPosition={'center'}
    //             backgroundRepeat={'no-repeat'}
    //             h={'45%'}
    //             minH={['250px', '250px', '300px', '400px']}
    //             w={['100%', '100%', '100%', '100%']}
    //             justifyContent={'flex-start'}
    //             alignItems={'flex-end'}
    //         // zoom the image in on hover
    //         // _hover={{
    //         //     backgroundSize: '110%',
    //         //     transition: 'all 6s ease'
    //         // }}
    //         >
    //             <VStack
    //                 ml={6}
    //                 mb={5}
    //                 spacing='1'
    //                 display={'flex'}
    //                 flexDirection={'column'}
    //                 // justifyContent={'start'}
    //                 alignItems={'center'}
    //             >
    //                 <Text
    //                     w={'100%'}
    //                     color={'#FFFFFF'}
    //                 >2024</Text>
    //                 <Text
    //                     w={'100%'}
    //                     fontSize={['2xl', '2xl', '2xl', '4xl']}
    //                     color={'#FFFFFF'}
    //                 >{props.item.name}</Text>
    //                 <HStack w='100%'>
    //                     <OutlinedButton
    //                         rounded='0'
    //                         type='submit'
    //                         width='120px'
    //                         color={'#FFFFFF'}
    //                         fontSize='16px'
    //                         border={'2px solid #FFFFFF'}
    //                         _hover={
    //                             {
    //                                 bg: 'none'
    //                             }
    //                         }
    //                     >
    //                         Shop Now</OutlinedButton>
    //                 </HStack>
    //             </VStack>

    //         </Flex>
    //     </Link>
    // )
}
