import React, { useEffect, useState } from 'react'
import { SimpleGrid, Text, Container, VStack, AspectRatio } from "@chakra-ui/react"
import { Link } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../../config';

export default function AllCollections(){

    const [Collections, setCollections] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const res = await axios.get(`${url}/collections`);
                console.log(res.data.data);
                setCollections(res.data.data);
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        };

        fetchCollections();
    }, []);


if(loading){
    return <Text pl={2}>Loading ...</Text>
}

  return (
    <Container maxW='8xl'>
    <VStack spacing='5' my={10}>
        <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Our Collections</Text>
        <Text>Our wonderful collections for you to explore.</Text>

        <SimpleGrid alignItems='center' justifyContent='center' w='100%' columns={[3, 3, 3, 6]} spacing={[4, 4, 6, 8]}>
            { Collections.map((col, index) => {
                return (
                    <Link key={index} to={`/caollections/${col.slug}`}>
                        <VStack w='100%' spacing='2'>
                            <AspectRatio ratio={1.04} w='100%'>
                                <img src={col.image} alt='collection' />
                            </AspectRatio>
                            <Text as='h2' className='customfont' fontSize={[14, 18, 18, 20, 22]}>
                                {col.name}
                            </Text>
                        </VStack>
                    </Link>
                )
            })}
        </SimpleGrid>
    </VStack>
</Container>
)
}
